import PerfectScrollbar from 'perfect-scrollbar';
import 'bootstrap'
import 'jquery-mask-plugin'

export default {
  init() {
    $(document).ready(function () {
      $('.info-tab li').on('click', 'a', function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
          top = $(id).offset().top - 130;
        $('body,html').animate({ scrollTop: top }, 400);
      });
    });

    function resize() {
      ps.update();
    }

    if($('.state__list').hasClass('state__list')) {
      var ps = new PerfectScrollbar('.state__list ul');
      window.onresize = resize;
    }

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $('.btn--burger').click( function() {
      $('.mobile-nav').addClass('show')
      $('body').addClass('blocked')
    })
    $('.mobile-nav .close').click( function(e) {
      e.preventDefault();
      $('.mobile-nav').removeClass('show')
      $('body').removeClass('blocked')
    })

    //mask
    $('#phone').mask('0000000000');
  },

  // JavaScript to be fired on all pages, after page specific JS is fired
  finalize() {},
};
